<template>
  <div>
    <BreadCrumb v-bind="breadCrumb" class="mb-5" />

    <div class="air__utils__heading">
      <h4 class="font-weight-bold">
        INDICADORES DO PROJETO COMUNIDADES DIGITAIS
      </h4>
    </div>

    <b-row align="center" style="d-flex; justify-content: center" class="mb-4">
      <b-col cols="3">
        <label>Data inicial:</label>
        <b-input
          v-model="entity.dateStart"
          placeholder="MM/AAAA"
          v-mask="'##-####'"
        >
        </b-input>
      </b-col>

      <b-col cols="3">
        <label>Data Final:</label>
        <b-input
          v-model="entity.dateEnd"
          placeholder="MM/AAAA"
          v-mask="'##-####'"
        >
        </b-input>
      </b-col>
    </b-row>

    <h4 class="text-center">
      <strong>Perguntas que irão ser geradas no relatório:</strong>
    </h4>

    <b-card style="box-shadow: 10px 10px 10px">
      <ul v-for="(item, idx) in questions" :key="idx">
        <li>{{ item.question }}</li>
      </ul>
    </b-card>

    <div class="text-center">
      <b-button @click="onGenerateReportAtc"> Gerar PDF </b-button>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/layout/BreadCrumb/breadCrumb.vue";
import ReportStudentRepository from "@/shared/http/repositories/socialProject/report-student.js";
import { toast, loading } from "@/shared/utils/UIHelper";
import { IsSuccessCode } from "@/shared/utils/API";
import download from "downloadjs";

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
  },

  data: () => ({
    questions: [
      { question: "Sala de aula disponível em horas", order: 1 },
      // {question: 'Cursos ministrados em horas', order: 2},
      { question: "Número de turmas oferecidos", order: 2 },
      {
        question: "Nr. de Matriculados nos dois cursos simultâneos",
        order: 3,
      },
      { question: "Número de estudantes que atenderam aos cursos", order: 4 },
      { question: "Percentual de frequência", order: 5 },
      { question: "Número de alunos formados ha 4 meses", order: 6 },
    ],
    entity: {
      dateStart: "",
      dateEnd: "",
    },
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: "Dashboard",
            href: this.$router.resolve({ name: "Dashboard" }).href,
          },
          {
            text: "Indicadores do Projeto Comunidades Digitais",
          },
        ],
      };
    },
  },

  methods: {
    onGenerateReportAtc() {
      if (!this.entity.dateStart) {
        toast.warning(
          "Insira uma data inicial!",
          "RELATÓRIO PROJETO COMUNIDADES DIGITAIS"
        );
        return;
      }
      this.questions = this.questions.sort((a, b) => a.order - b.order);

      if (!this.entity.dateEnd) {
        const dataAtual = new Date();
        const mesAtual = dataAtual.getMonth();
        const anoAtual = dataAtual.getFullYear();
        const mesString = mesAtual < 10 ? `0${mesAtual}` : `${mesAtual}`;
        const anoString = `${anoAtual}`;
        const result = `${mesString}-${anoString}`;

        this.entity.dateEnd = result;
      }

      loading.push();
      ReportStudentRepository.GetATC(
        this.entity.dateStart,
        this.entity.dateEnd,
        this.questions
      )
        .then((res) => {
          if (!IsSuccessCode(res)) return Promise.reject();

          const result = res.data.data;
          if (res.data.code >= 0) {
            if (result.noData) {
              toast.warning(
                "Não há registros nesta consulta!",
                "RELATÓRIO PROJETO COMUNIDADES DIGITAIS"
              );
              loading.pop();
              return;
            }
            toast.success("PDF Gerado!", "PROJETO COMUNIDADES DIGITAIS");
            const file = result.file;
            console.log(file);
            const base64 = `data:${file.contentType};base64,${file.base64}`;
            download(base64, file.alias, file.contentType);
          } else {
            toast.info("Ocorreu algum erro", "AVISO");
          }

          this.entity.dateEnd = null;
          this.entity.dateStart = null;

          loading.pop();
          return Promise.resolve();
        })
        .catch(() => {
          loading.pop();
          toast.error("Erro ao gerar PDF", "ERRO");
        });
    },
  },
};
</script>
